import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import ScrollLock from 'react-scrolllock'
import get from 'lodash/get'
import registry from '../../helpers/registry'
import LinkIcon from '../../images/svg/link.svg'
import './style.css'

const Menu = ({ isOpened }) => {
  const query = graphql`
    query SiteMenuQuery {
      prismic {
        links: allSite_configurations {
          edges {
            node {
              site_menu {
                page_link {
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Text_page {
                    title
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic }) => {
        const { site_menu } = get(prismic, 'links.edges[0].node')
        return (
          <ScrollLock isActive={isOpened}>
            <nav className={cx('menu', { '--opened': isOpened })}>
              <span className="menu__title">Menu</span>
              {site_menu.map(link => (
                <Link
                  to={`/${link.page_link._meta.uid}`}
                  key={link.page_link._meta.uid}
                  className="menu__item"
                  activeClassName="--active link-simple"
                >
                  {RichText.asText(link.page_link.title)}
                </Link>
              ))}
            </nav>
            <Link
              to={registry.contact}
              className="menu__contact cta-link link-simple"
            >
              <LinkIcon className="menu__contact__icon cta-link__icon" />
              Contact
            </Link>
          </ScrollLock>
        )
      }, query)}
    />
  )
}

Menu.propTypes = {
  isOpened: PropTypes.bool,
}

Menu.defaultProps = {
  isOpened: false,
}

export default Menu
