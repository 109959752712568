module.exports = {
  siteMetadata: {
    title: `signals Venture Capital Berlin`,
    short_name: 'signals.vc',
    description:
      'At signals Venture Capital we back visionary founders and top technology teams across Europe.',
    author: '@signalsvc',
    siteUrl: 'https://signals.vc',
  },
  defaultLang: 'en-de',
  themeColor: '#000000',
  backgroundColor: '#ffffff',
}
