export default {
  home: '/',
  contact: '/contact',
  newsletter: 'http://eepurl.com/htqFsT',
  newsletterTitle: 'newsletter',
  portfolio: '/portfolio',
  team: '/team',
  about: '/about',
  valueAdd: '/value-add',
  news: '/news',
  imprint: '/imprint',
  terms: '/toc',
  privacy: '/privacy',
}
