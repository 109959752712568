import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group'
import registry from '../../helpers/registry'
import Menu from '../Menu'
import FullMenu from '../FullMenu'
import Logo from '../../images/logo.svg'
import './style.css'

const useEventListener = (handler, cleanup, passive = false) => {
  useEffect(() => {
    window.addEventListener('scroll', handler, passive)

    return function remove() {
      cleanup()
      window.removeEventListener('scroll', handler)
    }
  }, ['scroll', handler, passive])
}

const Header = () => {
  const [menuIsOpened, setMenuIsOpened] = useState(false)
  const [menuIsFixed, setMenuIsFixed] = useState(false)

  const scrollHandler = useCallback(
    _.throttle(
      () => {
        setMenuIsFixed(window.scrollY > 120 || menuIsOpened)
      },
      100,
      { leading: false }
    ),
    []
  )

  useEventListener(scrollHandler, scrollHandler.cancel)

  return (
    <header className={cx('header', { '--fixed': menuIsFixed })}>
      <h1 className="header__logo">
        <Link to={registry.home} className="header__logo__link">
          <img src={Logo} alt="Signals Venture Capital" />
        </Link>
      </h1>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={cx('menu-btn', {
          '--opened': menuIsOpened,
          '--fixed': menuIsFixed,
        })}
        onClick={() => setMenuIsOpened(!menuIsOpened)}
      >
        <span className="menu-btn__open-icon" />
      </div>
      <Menu isOpened={menuIsOpened} isSticky={menuIsFixed} />
      <CSSTransition
        classNames="animation-popup"
        in={menuIsOpened}
        unmountOnExit
        timeout={{
          enter: 100,
          exit: 300,
        }}
      >
        <div>
          <FullMenu
            isOpened={menuIsOpened}
            onClose={() => {
              setMenuIsOpened(false)
            }}
          />
        </div>
      </CSSTransition>
    </header>
  )
}

export default Header
