import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import cx from 'classnames'
import get from 'lodash/get'
import './style.css'

const Menu = ({ isOpened, onClose }) => {
  const query = graphql`
    query SiteFullMenuQuery {
      prismic {
        links: allSite_configurations {
          edges {
            node {
              site_menu {
                page_link {
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Text_page {
                    title
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
        address: allContacts {
          edges {
            node {
              address
              contact_methods
            }
          }
        }
        socials: allSocial_networks {
          edges {
            node {
              name
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic }) => {
        const { site_menu } = get(prismic, 'links.edges[0].node')
        const { address, contact_methods } = get(
          prismic,
          'address.edges[0].node'
        )
        const socials = get(prismic, 'socials.edges') || []

        return (
          <div className={cx('full-menu', { '--opened': isOpened })}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="full-menu__back" onClick={onClose} />
            <div className="full-menu__i">
              <nav className="full-menu__col --nav">
                <h3 className="full-menu__title">Menu</h3>
                {site_menu.map(link => (
                  <div className="full-menu__item">
                    <Link
                      to={`/${link.page_link._meta.uid}`}
                      key={link.page_link._meta.uid}
                      className="full-menu__item__i underlined-dark"
                      activeClassName="--active"
                    >
                      {RichText.asText(link.page_link.title)}
                    </Link>
                  </div>
                ))}
              </nav>
              <nav className="full-menu__col --social">
                <h3 className="full-menu__title">Social</h3>
                {socials.map(item => (
                  <div className="full-menu__item">
                    <a
                      href={item.node.link.url}
                      key={item.node.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="full-menu__item__i underlined-dark"
                    >
                      {item.node.name}
                    </a>
                  </div>
                ))}
              </nav>
              <div className="full-menu__col --contact">
                <h3 className="full-menu__title">Contact</h3>
                <div className="full-menu__content">
                  {RichText.render(address)}
                </div>
                <div className="full-menu__content">
                  {RichText.render(contact_methods)}
                </div>
              </div>
            </div>
          </div>
        )
      }, query)}
    />
  )
}

Menu.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
}

Menu.defaultProps = {
  isOpened: false,
  onClose: () => {},
}

export default Menu
