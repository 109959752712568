import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import get from 'lodash/get'
import './style.css'

const setLocalStorageCookiesAccepted = changeState => {
  window.localStorage.setItem('cookiesAccepted', 'true')
  changeState('true')
}

const Cookies = () => {
  const query = graphql`
    query CookiesQuery {
      prismic {
        content: allSite_configurations {
          edges {
            node {
              cookies_disclaimer_text
              cookies_accept_button
            }
          }
        }
      }
    }
  `

  const [cookiesAccepted, setCookiesAccepted] = useState('true')

  useEffect(() => {
    if (window.localStorage.getItem('cookiesAccepted') !== 'true') {
      setCookiesAccepted('')
    }
  })

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic }) => {
        const { cookies_disclaimer_text, cookies_accept_button } = get(
          prismic,
          'content.edges[0].node'
        )

        if (cookiesAccepted === 'true') return null

        return (
          <div className="cookies">
            {cookies_disclaimer_text && (
              <div className="cookies__i">
                {RichText.render(cookies_disclaimer_text)}
              </div>
            )}
            {cookies_accept_button && (
              <button
                type="button"
                className="btn cookies__btn"
                onClick={() =>
                  setLocalStorageCookiesAccepted(setCookiesAccepted)
                }
              >
                {cookies_accept_button}
              </button>
            )}
          </div>
        )
      }, query)}
    />
  )
}

Cookies.propTypes = {}

export default Cookies
